import { useCallback, useState } from "react";
import { Button, TextField, Typography } from "@mui/material";
import { useFirebase } from "../../context/FirebaseApp";
import { getAuth, sendSignInLinkToEmail } from "firebase/auth";

export function EmailSection({ setSnackBarMessage }) {
  const firebase = useFirebase();
  const [email, setEmail] = useState("");

  const signInWithMagicEmail = useCallback(() => {
    const auth = getAuth(firebase);
    console.log("using email", email);

    sendSignInLinkToEmail(auth, email, {
      url: "https://coinswap.razza.io/email",
      dynamicLinkDomain: "coinswap.razza.io",
    })
      .then(() => {
        setSnackBarMessage("Check your email and click the link.");
        window.localStorage.setItem("auth:magic-email:email", email);
      })
      .catch((err) => {
        console.error("Failed to send magic email link", err);
        console.error("Failed to send magic email link", err.message);
        setSnackBarMessage("Failed to send magic email.");
      });
  }, [firebase, setSnackBarMessage, email]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        gap: 8,
      }}
    >
      <Typography variant="caption" align="center" component="div">
        Sign in with Email
      </Typography>
      <TextField
        variant="standard"
        label="Email"
        fullWidth
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Button variant="contained" onClick={signInWithMagicEmail} fullWidth>
        Send Link
      </Button>
    </div>
  );
}
