import {
  Button,
  ButtonGroup,
  Divider,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import {
  getAuth,
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
} from "firebase/auth";
import { useFirebase } from "../context/FirebaseApp";
import { useCallback, useState } from "react";
import { EmailSection } from "./AuthPage/EmailSection";

export function AuthPage() {
  const firebase = useFirebase();
  const [snackBarMessage, setSnackBarMessage] = useState<string>("");

  const signInWithPhoneNumber = useCallback(() => {}, [firebase]);
  const signInWithGoogle = useCallback(() => {}, [firebase]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        gap: 8,
      }}
    >
      <EmailSection setSnackBarMessage={setSnackBarMessage} />
      <Divider />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          gap: 8,
        }}
      >
        <Typography variant="caption" align="center" component="div">
          or with
        </Typography>
        <ButtonGroup orientation="vertical" variant="outlined" fullWidth>
          <Button>Google</Button>
        </ButtonGroup>
      </div>

      <Snackbar
        open={snackBarMessage != ""}
        autoHideDuration={6000}
        onClose={() => setSnackBarMessage("")}
        message={snackBarMessage}
      />
    </div>
  );
}
