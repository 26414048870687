import { initializeApp } from "firebase/app";
import { createContext, useContext } from "react";

const firebaseConfig = {
  apiKey: "AIzaSyBpPTXfXB0DcWMJfgn_lX4dlZnOXJUGA_4",
  authDomain: "coinswap-daee5.firebaseapp.com",
  projectId: "coinswap-daee5",
  storageBucket: "coinswap-daee5.appspot.com",
  messagingSenderId: "412266355071",
  appId: "1:412266355071:web:04a8b65eef5fd611e1a24a",
  measurementId: "G-RZ3EVS28QP",
};

const app = initializeApp(firebaseConfig);

export const firebaseApp = app;
export const FirebaseContext = createContext(app);

export const useFirebase = () => useContext(FirebaseContext);

export function FirebaseProvider({ children }) {
  return (
    <FirebaseContext.Provider value={app}>{children}</FirebaseContext.Provider>
  );
}
