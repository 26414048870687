import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";
import { HomePage } from "./pages/HomePage";
import { AuthPage } from "./pages/AuthPage";
import { ThemeProvider, createTheme } from "@mui/material";
import { FirebaseProvider } from "./context/FirebaseApp";

const router = createBrowserRouter([
  {
    path: "/",
    element: <AuthPage />,
  },
]);

const theme = createTheme({
  typography: {
    allVariants: { fontFamily: "Work Sans, sans-serif" },
    button: { textTransform: "none" },
  },
  components: { MuiButton: {} },
});

export function App() {
  return (
    <ThemeProvider theme={theme}>
      <FirebaseProvider>
        <RouterProvider router={router} />
      </FirebaseProvider>
    </ThemeProvider>
  );
}
